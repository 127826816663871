import { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Room, Property as PropertyProps } from '../utils/interfaces';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  InputLabel,
  TableCell,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  Box,
  Modal,
  Backdrop,
  Fade,
  TablePagination,
  Typography,
} from '@mui/material';
import Spinner from '../utils/Spinner';
import { useTranslation } from 'react-i18next';
import ErrorMessages from '../helper/ErrorMessages';
import { useUserStore } from '../stores/UserStore';
import { usePropertyStore } from '../stores/PropertyStore';
import { deleteKeyStore } from '../utils/indexedDB';

function Property() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, loadUserFromIndexedDB } = useUserStore();
  const { properties, loadPropertiesFromIndexedDB } = usePropertyStore();
  const [roomCreationLoading, setRoomCreationLoading] = useState(false);
  const errorMessages = new ErrorMessages();
  const [formData, setFormData] = useState<PropertyProps>({
    PropertyID: '',
    UserID: '',
    PropertyType: '',
    PropertyName: '',
    Price: 0,
    NumberOfUnits: 0,
    NumberOfBathrooms: 0,
    NumberOfBedrooms: 0,
    Location: '',
  });
  // const [properties, setProperties] = useState<PropertyProps[]>([]);
  const [selectedPropertyName, setSelectedPropertyName] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Fetch user details from IndexedDB or API
  useEffect(() => {
    const fetchUser = async () => {
      try {
        await loadUserFromIndexedDB();
      } catch (error) {
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchUser();
  }, [loadUserFromIndexedDB]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        await loadPropertiesFromIndexedDB();
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchProperties();
  }, [loadPropertiesFromIndexedDB]);

  // Handle property type selection change
  const handleTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setFormData({ ...formData, PropertyType: event.target.value as string });
  };

  // Handle input change for form fields
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // Convert numeric fields to number type or default to 0
    setFormData({
      ...formData,
      [name]:
        name === 'Price' ||
        name === 'NumberOfUnits' ||
        name === 'NumberOfBathrooms' ||
        name === 'NumberOfBedrooms'
          ? value === ''
            ? 0
            : Number(value)
          : value,
    });
  };

  // Handle form submission
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const propertyData: PropertyProps = { ...formData };

    try {
      setRoomCreationLoading(true);

      const response = await api.createProperty(propertyData);
      console.log('Property created:', response);

      if (response.PropertyID) {
        let allRoomsCreated = true;

        for (let i = 0; i < (propertyData.NumberOfUnits || 1); i++) {
          const roomData: Room = {
            PropertyID: response.PropertyID,
            PropertyName: propertyData.PropertyName,
            Price: propertyData.Price,
          };

          try {
            // Await each room creation to ensure sequential execution
            await api.createRoom(roomData);
          } catch (error: any) {
            allRoomsCreated = false; // Stop further room creation
            const errorMessage = errorMessages.getErrorMessage(
              error.statusCode,
              error.context
            );
            toast.error(errorMessage);
            break; // Exit the loop if an error occurs
          }
        }

        if (allRoomsCreated) {
          await deleteKeyStore('properties');
          await loadPropertiesFromIndexedDB();
          toast.success(
            `${propertyData.PropertyName} building with rooms created:`
          );
          setFormData({
            PropertyID: '',
            UserID: '',
            PropertyType: '',
            PropertyName: '',
            Price: 0,
            NumberOfUnits: 0,
            NumberOfBathrooms: 0,
            NumberOfBedrooms: 0,
            Location: '',
          });
          setModalOpen(false);
        }
      }
    } catch (error: any) {
      const errorMessage = errorMessages.getErrorMessage(
        error.statusCode,
        error.context
      );

      toast.error(errorMessage);
    } finally {
      setRoomCreationLoading(false);
      await deleteKeyStore('properties');
      await loadPropertiesFromIndexedDB();
    }
  };

  // Handle click on property to navigate to details
  const handlePropertyClick = (property: PropertyProps) => {
    navigate(`/property/${property.PropertyID}`, { state: { property } });
  };

  // Handle change in property name filter dropdown
  const handlePropertyNameFilterChange = (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedPropertyName(event.target.value as string);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Common form fields JSX
  const commonFields = (
    <>
      {/* Input fields for property details */}
      <TextField
        className="form-control"
        label={t('propertyName')}
        type="text"
        name="PropertyName"
        value={formData.PropertyName}
        onChange={handleInputChange}
        fullWidth
        required
      />

      <TextField
        className="form-control"
        label={t('numberOfUnits')}
        type="number"
        name="NumberOfUnits"
        value={formData.NumberOfUnits === 0 ? '' : formData.NumberOfUnits}
        onChange={handleInputChange}
        fullWidth
        required
      />

      {formData.PropertyType !== 'Hostel' && ( // Render only if PropertyType is not 'Hostel'
        <>
          <TextField
            className="form-control"
            label={t('numberOfBathrooms')}
            type="number"
            name="NumberOfBathrooms"
            value={
              formData.NumberOfBathrooms === 0 ? '' : formData.NumberOfBathrooms
            }
            onChange={handleInputChange}
            fullWidth
            required
          />

          <TextField
            className="form-control"
            label={t('numberOfBedrooms')}
            type="number"
            name="NumberOfBedrooms"
            value={
              formData.NumberOfBedrooms === 0 ? '' : formData.NumberOfBedrooms
            }
            onChange={handleInputChange}
            fullWidth
            required
          />
        </>
      )}

      <TextField
        className="form-control"
        label={t('price')}
        type="number"
        name="Price"
        value={formData.Price === 0 ? '' : formData.Price}
        onChange={handleInputChange}
        fullWidth
        required
      />

      <TextField
        className="form-control"
        label={t('location')}
        type="text"
        name="Location"
        value={formData.Location}
        onChange={handleInputChange}
        fullWidth
        required
      />
    </>
  );

  // Apply filter to properties based on selected property name
  const filteredProperties = selectedPropertyName
    ? properties?.filter(
        (property) => property.PropertyName === selectedPropertyName
      )
    : properties;

  const propertiesToDisplay = filteredProperties?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Render the component UI
  return (
    <div className="overview-container">
      <main className="property-content">
        {/* Main property management section */}
        <div className="prop-win p-4">
          {/* Filter and clear filter button */}
          <div
            className="filter-container"
            style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            {/* Filter by property name dropdown */}
            <FormControl style={{ width: '20%' }}>
              <InputLabel id="property-name-filter-label">
                {t('filterByPropertyName')}
              </InputLabel>
              <Select
                labelId="property-name-filter-label"
                id="property-name-filter"
                value={selectedPropertyName}
                //@ts-ignore
                onChange={handlePropertyNameFilterChange}
              >
                <MenuItem value="">
                  <em>{t('allProperties')}</em>
                </MenuItem>
                {/* List of property names for filtering */}
                {properties?.map((property) => (
                  <MenuItem
                    key={property.PropertyID}
                    value={property.PropertyName}
                  >
                    {property.PropertyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Button to clear property name filter */}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSelectedPropertyName('')}
            >
              {t('clearFields')}
            </Button>

            {/* Submit button on the left */}
            {user?.UserRoleType !== 'Caretaker' && (
              <Button
                variant="contained"
                color="primary"
                sx={{ mr: 2, my: 1 }}
                onClick={() => setModalOpen(true)}
              >
                {t('add_new')}
              </Button>
            )}
          </div>
          {/* Table displaying properties */}
          <div
            className="property-table-container"
            style={{ overflowY: 'auto' }}
          >
            {loading || roomCreationLoading ? (
              <Spinner />
            ) : (
              <>
                <Table className="table table-bordered">
                  <TableHead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1000,
                      backgroundColor: '#d5deee',
                    }}
                  >
                    <TableRow>
                      <TableCell>{t('propertyType')}</TableCell>
                      <TableCell>{t('propertyName')}</TableCell>
                      <TableCell>{t('numberOfUnits')}</TableCell>
                      <TableCell>{t('price')}</TableCell>
                      <TableCell>{t('location')}</TableCell>
                    </TableRow>
                  </TableHead>

                  {/* Table body showing filtered properties */}
                  <TableBody>
                    {propertiesToDisplay?.map((property) => (
                      <TableRow
                        key={property.PropertyID}
                        onClick={() => handlePropertyClick(property)}
                        className="table-row"
                      >
                        <TableCell>{property.PropertyType}</TableCell>
                        <TableCell>{property.PropertyName}</TableCell>
                        <TableCell>{property.NumberOfUnits}</TableCell>
                        <TableCell>{property.Price}</TableCell>
                        <TableCell>{property.Location}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                {/* Pagination controls */}
                <div
                  style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: '#fff',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '4px', // Reduced padding to decrease height
                    zIndex: 1000,
                  }}
                >
                  <Typography variant="body2">
                    {t('page_of', {
                      page: page + 1,
                      totalPages: Math.ceil(
                        (filteredProperties?.length ?? 0) / (rowsPerPage || 1)
                      ),
                    })}
                  </Typography>
                  <TablePagination
                    component="div"
                    count={filteredProperties?.length ?? 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                    sx={{
                      '.MuiTablePagination-toolbar': { minHeight: '36px' },
                    }} // Reduced height of pagination toolbar
                  />
                </div>
              </>
            )}
          </div>
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="add-property-modal"
            aria-describedby="modal-to-add-new-property"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={modalOpen}>
              <div className="modal-content">
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="prop-type-dropdown-label">
                          {t('selectPropertyType')}
                        </InputLabel>
                        <Select
                          labelId="prop-type-dropdown-label"
                          id="prop-type-dropdown"
                          value={formData.PropertyType}
                          //@ts-ignore
                          onChange={handleTypeChange}
                          fullWidth
                          required
                        >
                          {/* Dropdown options for property types */}
                          <MenuItem value="House">{t('house')}</MenuItem>
                          <MenuItem value="Apartment">
                            {t('apartment')}
                          </MenuItem>
                          <MenuItem value="Hostel">{t('hostel')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {/* Render common form fields based on property type */}
                      {formData.PropertyType && (
                        <div
                          className={`${formData.PropertyType.toLowerCase()}-group`}
                        >
                          {commonFields}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {/* Button Container */}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {/* Clear button on the right */}
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            setFormData({
                              PropertyID: '',
                              UserID: '',
                              PropertyType: '',
                              PropertyName: '',
                              Price: 0,
                              NumberOfUnits: 0,
                              NumberOfBathrooms: 0,
                              NumberOfBedrooms: 0,
                              Location: '',
                            })
                          }
                        >
                          {t('clearFields')}
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="mt-3"
                        >
                          {t('submit')}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Fade>
          </Modal>

          {/* Spinner component */}
          {roomCreationLoading && (
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999,
              }}
            >
              <Spinner />
            </Box>
          )}
        </div>
      </main>
    </div>
  );
}

export default Property;

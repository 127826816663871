export const validateEmail = (email: string): boolean => {
  const patternEmail = new RegExp(
    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
  );
  return patternEmail.test(email);
};

export const validatePassword = (password: string): boolean => {
  const patternPass = new RegExp(
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-=])[\\S]{8,}'
  );
  return patternPass.test(password);
};

import React, { useEffect, useState } from 'react';
import { api } from '../utils/api';
import LineChart from '../helper/LineChart';
import { toast } from 'react-toastify';
import Spinner from '../utils/Spinner';
import { useTranslation } from 'react-i18next';

interface Tenant {
  StartDate: string;
  EndDate: string;
  RoomPrice: number;
}

interface RentalIncomeResponse {
  TotalRooms: number;
  AvgRoomPrice: string;
  Tenants: Tenant[];
}

interface BarChartDataItem {
  Year: number;
  TotalRent: number;
}

function YearlyAnalysis() {
  const { t } = useTranslation();
  const [yearlyData, setYearlyData] = useState<BarChartDataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const currencySymbol = localStorage.getItem('Currency') || '$';

  useEffect(() => {
    const fetchData = async () => {
      try {
        //@ts-ignore
        const response: RentalIncomeResponse = await api.getRentalIncome();
        const tenants = response.Tenants;

        const currentYear = new Date().getFullYear();
        const startYear = currentYear - 5;
        const endYear = currentYear;

        const yearlyData: BarChartDataItem[] = [];

        for (let year = startYear; year <= endYear; year++) {
          let totalRent = 0;

          tenants.forEach((tenant) => {
            const contractStart = new Date(tenant.StartDate);
            const contractEnd = new Date(tenant.EndDate);

            // Calculate the overlap period between the tenant's contract and the current year
            const start = new Date(
              //@ts-ignore
              Math.max(contractStart, new Date(`${year}-01-01`))
            );
            const end = new Date(
              //@ts-ignore
              Math.min(contractEnd, new Date(`${year}-12-31`))
            );

            if (start <= end) {
              const monthsActive = calculateMonths(start, end);
              const yearlyRent = (tenant.RoomPrice / 12) * monthsActive;
              totalRent += yearlyRent;
            }
          });

          yearlyData.push({
            Year: year,
            TotalRent: totalRent,
          });
        }

        setYearlyData(yearlyData);
        setLoading(false);
      } catch (error) {
        //@ts-ignore
        toast.warning(error.message);
        setError(t('noData'));
        setLoading(false);
      }
    };

    fetchData();
  }, [t]);

  const calculateMonths = (start: Date, end: Date) => {
    const startMonth = start.getMonth();
    const startYear = start.getFullYear();
    const endMonth = end.getMonth();
    const endYear = end.getFullYear();

    let months = (endYear - startYear) * 12 + (endMonth - startMonth + 1);
    if (startMonth > endMonth) {
      months -= 12;
    }

    return months;
  };

  // Function to format number with commas and currency symbol
  const formatCurrency = (amount: number) => {
    return `${currencySymbol}${amount.toLocaleString()}`;
  };

  // Prepare data for LineChart component
  const lineChartData = {
    labels: yearlyData.map((item) => item.Year.toString()),
    datasets: [
      {
        label: t('yearlyRentAmount'),
        data: yearlyData.map((item) => item.TotalRent),
        fill: false,
        borderColor: '#007bff',
      },
    ],
  };

  // Calculate total revenue
  const totalRevenue = yearlyData.reduce(
    (total, item) => total + item.TotalRent,
    0
  );

  // Display accumulated profit as revenue if expenses are not provided
  const accumulatedProfit = totalRevenue;

  // Calculate percentage revenue
  const percentageRevenue = (accumulatedProfit / totalRevenue) * 100;

  // Loading state UI
  if (loading) {
    return <Spinner />;
  }

  // Error state UI
  if (error) {
    return (
      <div className="overview-container">
        <div className="container">
          <h2>{t('yearlyAnalysis')}</h2>
          <p>{t('noData')}</p>
        </div>
      </div>
    );
  }

  // Rendered UI when data is loaded successfully
  return (
    <div className="overview-container">
      <main className="yearlyAnalysis-content">
        <div className="container">
          <h2>{t('yearlyAnalysis')}</h2>
          <div className="chart-container">
            <LineChart data={lineChartData} />
          </div>

          {/* Additional data table */}
          <table>
            <thead>
              <tr>
                <th>{t('profitWithoutExpenses')}</th>
                <th>{t('revenue')}</th>
                <th>{t('percentageRevenue')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formatCurrency(accumulatedProfit)}</td>
                <td>{formatCurrency(totalRevenue)}</td>
                <td>{percentageRevenue}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}

export default YearlyAnalysis;

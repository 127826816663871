import { create } from 'zustand';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Property } from '../utils/interfaces';

interface PropertyStore {
  properties: Property[] | null;
  setProperties: (properties: Property[]) => void;
  fetchPropertiesFromApi: () => Promise<void>;
  loadPropertiesFromIndexedDB: () => Promise<void>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const usePropertyStore = create<PropertyStore>((set) => ({
  properties: null,
  loading: false,

  // Function to set properties in the store
  setProperties: (properties: Property[]) => set({ properties }),

  // Function to set loading state
  setLoading: (loading: boolean) => set({ loading }),

  // Function to load properties data from IndexedDB
  loadPropertiesFromIndexedDB: async () => {
    try {
      set({ loading: true });
      const savedProperties = await getData('properties');

      if (savedProperties && savedProperties.data.length > 0) {
        const decryptedData = decryptData(savedProperties.data);
        const properties: Property[] = JSON.parse(decryptedData);
        set({ properties }); // Set the properties in the Zustand store
      } else {
        // If no data in IndexedDB, fetch from API
        await usePropertyStore.getState().fetchPropertiesFromApi();
      }

      set({ loading: false });
    } catch (error) {
      // @ts-ignore
      toast.error('Failed to load properties from IndexedDB');
      // @ts-ignore
      console.error('Error loading properties from IndexedDB:', error.message);
      set({ loading: false });
    }
  },

  // Function to fetch properties from the API and save to IndexedDB
  fetchPropertiesFromApi: async () => {
    try {
      set({ loading: true });
      const response = await api.getPropertiesByUser();
      //@ts-ignore
      const sortedProperties = response.sort((a, b) =>
        a.PropertyName.localeCompare(b.PropertyName)
      ); // Sorting properties alphabetically by 'PropertyName'

      // Save the properties to Zustand state
      set({ properties: sortedProperties });

      // Encrypt and save the properties data to IndexedDB
      const encryptedData = encryptData(JSON.stringify(sortedProperties));
      await saveData('properties', encryptedData);

      set({ loading: false });
    } catch (error) {
      // @ts-ignore
      toast.error(error.message);
      set({ loading: false });
      // @ts-ignore
      console.error('Error fetching properties:', error.message);
    }
  },
}));

import React, { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TermsConditionsModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Button
        onClick={handleOpen}
        variant="outlined"
        sx={{ color: '#000', borderColor: '#000' }}
      >
        {t('terms_conditions')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="terms-conditions-modal-title"
        aria-describedby="terms-conditions-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: '#fff',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            maxHeight: '40vh',
            overflowY: 'auto',
          }}
        >
          <Typography
            variant="h5"
            id="terms-conditions-modal-title"
            gutterBottom
          >
            {t('terms_conditions')}
          </Typography>
          <Typography id="terms-conditions-modal-description" sx={{ mt: 2 }}>
            <p>
              <strong>{t('terms_conditions_section_1_title')}</strong>{' '}
              {t('terms_conditions_section_1_description')}
            </p>
            <p>
              <strong>{t('terms_conditions_section_2_title')}</strong>{' '}
              {t('terms_conditions_section_2_description')}
            </p>
            <p>
              <strong>{t('terms_conditions_section_3_title')}</strong>{' '}
              {t('terms_conditions_section_3_description')}
            </p>
            <p>
              <strong>{t('terms_conditions_section_4_title')}</strong>{' '}
              {t('terms_conditions_section_4_description')}
            </p>
            <p>
              <strong>{t('terms_conditions_section_5_title')}</strong>{' '}
              {t('terms_conditions_section_5_description')}
            </p>
            <p>
              <strong>{t('terms_conditions_section_6_title')}</strong>{' '}
              {t('terms_conditions_section_6_description')}
            </p>
            <p>
              <strong>{t('terms_conditions_section_7_title')}</strong>{' '}
              {t('terms_conditions_section_7_description')}
            </p>
            <p>
              <strong>{t('terms_conditions_section_8_title')}</strong>{' '}
              {t('terms_conditions_section_8_description')}
            </p>
            <p>
              <strong>{t('terms_conditions_section_9_title')}</strong>{' '}
              {t('terms_conditions_section_9_description')}
            </p>
            <p>
              <strong>{t('terms_conditions_section_10_title')}</strong>{' '}
              {t('terms_conditions_section_10_description')}
            </p>
          </Typography>
          <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
            {t('close')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default TermsConditionsModal;

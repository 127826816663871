import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../utils/api';
import { validatePassword } from '../helper/validation';
import { ApiResponse, Token } from '../utils/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert,
  IconButton,
  InputAdornment,
} from '@mui/material';

interface ChangePasswordParams {
  Email: string;
  NewPassword: string;
}

function ResetPassword() {
  const { token } = useParams<Token>();
  const [resetEmailStatus, setResetEmailStatus] =
    useState<string>('Verifying...');
  const [newPassword, setNewPassword] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [errorMsgRegister, setErrorMsgRegister] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  useEffect(() => {
    if (token) {
      // Checking if token exists
      api
        .verifyResetLink(token)
        .then((response: ApiResponse) => {
          if (response.success) {
            setIsSuccess(true);
            setResetEmailStatus('Reset Password Successfully');
            //@ts-ignore
            setUserEmail(response.success.userEmail);
          } else {
            setIsSuccess(false);
            setResetEmailStatus('Verification Failed');
          }
        })
        .catch(() => {
          setIsSuccess(false);
          setResetEmailStatus('Reset Password Verification Failed');
        });
    } else {
      console.error('Token is undefined');
      setIsSuccess(false);
    }
  }, [token]);

  // Event handler for handling changes in the new password input
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  // Event handler for handling changes in the confirm password input
  const handlePasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  // Function to handle password change button click
  const handleChangePassword = (): void => {
    if (!validatePassword(newPassword) || newPassword !== confirmPassword) {
      console.log('Validation failed');
      setErrorMsgRegister('Passwords do not match.');
      return;
    }

    api
      .changePassword({
        Email: userEmail,
        NewPassword: newPassword,
      } as ChangePasswordParams)
      .then(() => {
        setPasswordChanged(true);
        setResetEmailStatus('Password changed successfully');
      })
      .catch((error: Error) => {
        console.error('Error changing password:', error);
      });
  };

  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  // Rendering password changed success message if passwordChanged state is true
  if (passwordChanged) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <div className="success-message">
          <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
          <p>{resetEmailStatus}</p>
        </div>
      </Container>
    );
  }

  // Rendering reset password form and verification status
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Reset Password
      </Typography>
      {isSuccess ? (
        // Rendering password change form if reset email verification is successful
        <Box component="form" sx={{ mt: 2, width: '100%', maxWidth: '400px' }}>
          <TextField
            fullWidth
            type={showNewPassword ? 'text' : 'password'}
            label="New Password"
            value={newPassword}
            onChange={handlePasswordChange}
            margin="normal"
            sx={{ height: '50px' }}
            InputProps={{
              sx: { height: '50px' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleNewPasswordVisibility} edge="end">
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm Password"
            value={confirmPassword}
            onChange={handlePasswordConfirmChange}
            margin="normal"
            sx={{ height: '50px' }}
            InputProps={{
              sx: { height: '50px' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errorMsgRegister && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMsgRegister}
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangePassword}
            sx={{ mt: 2 }}
          >
            Change Password
          </Button>
        </Box>
      ) : (
        // Displaying verification failed message if reset email verification fails
        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
          <div className="failure-message">
            <FontAwesomeIcon icon={faTimesCircle} className="failure-icon" />
            <p>Verification Failed</p>
          </div>
        </Typography>
      )}
    </Container>
  );
}

export default ResetPassword;

import i18n from '../i18n';

interface ErrorMessage {
  code: number;
  key: string;
  context?: string;
}

class ErrorMessages {
  private errors: ErrorMessage[];

  constructor() {
    this.errors = [
      { code: 500, key: 'errors.internal' },
      { code: 400, key: 'errors.invalid' },
      { code: 401, key: 'errors.forbidden' },
      { code: 200, context: 'updated', key: 'errors.exceeded_limit' },
      { code: 400, context: 'limit', key: 'errors.exceeded_limit' },
      {
        code: 400,
        context: 'activeRequest',
        key: 'errors.request.activeRequest',
      },
      {
        code: 400,
        context: 'pendingRequest',
        key: 'errors.request.pendingRequest',
      },
      { code: 400, context: 'revoked', key: 'errors.request.revoked' },
      { code: 400, context: 'duplicate', key: 'errors.room_duplicate' },
      { code: 403, context: 'unauthorized', key: 'errors.unauthorized_access' },
      { code: 403, context: 'occupied', key: 'errors.occupied' },
      { code: 404, context: 'notfound', key: 'errors.not_found.not_found' },
      { code: 404, context: 'user', key: 'errors.not_found.user' },
      {
        code: 404,
        context: 'promocode',
        key: 'errors.not_found.not_found.promocode',
      },
      { code: 404, context: 'property', key: 'errors.not_found.property' },
      { code: 404, context: 'tenant', key: 'errors.not_found.tenant' },
      { code: 404, context: 'room', key: 'errors.not_found.room' },
      {
        code: 404,
        context: 'subscription',
        key: 'errors.not_found.subscription',
      },
      // add more error codes and corresponding translation keys
    ];
  }

  getErrorMessage(code: number, context?: string): string {
    const error = this.errors.find(
      (err) => err.code === code && (!context || err.context === context)
    );
    return error ? i18n.t(error.key) : i18n.t('errors.unknown_error');
  }
}

export default ErrorMessages;

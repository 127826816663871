import { create } from 'zustand';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Tenant } from '../utils/interfaces';
import { calculateStatus } from '../utils/dateUtils';
import { t } from 'i18next';

interface TenantStore {
  tenants: Tenant[] | null;
  setTenants: (tenants: Tenant[]) => void;
  fetchTenantsFromApi: () => Promise<void>;
  loadTenantsFromIndexedDB: () => Promise<void>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const useTenantStore = create<TenantStore>((set) => ({
  tenants: null,
  loading: false,

  // Function to set tenants in the store
  setTenants: (tenants: Tenant[]) => set({ tenants }),

  // Function to set loading state
  setLoading: (loading: boolean) => set({ loading }),

  // Function to load tenants data from IndexedDB
  loadTenantsFromIndexedDB: async () => {
    try {
      set({ loading: true });
      const savedTenants = await getData('tenants');

      if (savedTenants && savedTenants.data.length > 0) {
        const decryptedData = decryptData(savedTenants.data);
        const tenants: Tenant[] = JSON.parse(decryptedData);
        set({ tenants }); // Set the tenants in the Zustand store
      } else {
        // If no data in IndexedDB, fetch from API
        await useTenantStore.getState().fetchTenantsFromApi();
      }

      set({ loading: false });
    } catch (error) {
      toast.error('Failed to load tenants from IndexedDB');
      set({ loading: false });
    }
  },

  // Function to fetch tenants from the API and save to IndexedDB
  fetchTenantsFromApi: async () => {
    try {
      set({ loading: true });
      const response = await api.getTenants();

      const updatedTenants = response.map((tenant: Tenant) => {
        const status = calculateStatus(tenant.EndDate);
        const translatedStatus = t(status.toLowerCase());
        return {
          ...tenant,
          Status: translatedStatus,
        };
      });

      // Sorting tenants alphabetically by FirstName and LastName combined
      updatedTenants.sort((a, b) => {
        const nameA = `${a.FirstName} ${a.LastName}`.toUpperCase();
        const nameB = `${b.FirstName} ${b.LastName}`.toUpperCase();
        return nameA.localeCompare(nameB);
      });

      // Save the tenants to Zustand state
      set({ tenants: updatedTenants });

      // Encrypt and save the tenants data to IndexedDB
      const encryptedData = encryptData(JSON.stringify(updatedTenants));
      await saveData('tenants', encryptedData);

      set({ loading: false });
    } catch (error) {
      // @ts-ignore
      toast.error(`Error fetching data: ${error.message}`);
      set({ loading: false });
    }
  },
}));

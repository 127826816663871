import { Container, Typography, Box, Grid, Link } from '@mui/material';
import { Email, Phone, LocationOn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const subscription = [
  {
    name: 'Free',
    price: '$0',
    monthly: 'Monthly',
    features: ['1 Property', '10 Tenants'],
    styleClass: 'free',
  },
  {
    name: 'Basic',
    price: '$5',
    monthly: 'Monthly',
    features: ['3 Properties', '50 Tenants'],
    styleClass: 'basic',
  },
  {
    name: 'Standard',
    price: '$10',
    monthly: 'Monthly',
    features: ['Unlimited Properties', '200 Tenants'],
    styleClass: 'standard',
  },
  {
    name: 'Premium',
    price: '$15',
    monthly: 'Monthly',
    features: ['No Limits', 'Analysis Overview'],
    styleClass: 'premium',
  },
];

const AboutUs = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const currentYear = new Date().getFullYear();

  return (
    <div className="about-us-container">
      <main className="about-us-content">
        <Box sx={{ padding: '40px 20px' }}>
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{ color: '#333', fontWeight: 'bold', marginBottom: '30px' }}
            >
              {t('aboutUs.title')}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: '18px', lineHeight: 1.8, marginBottom: '40px' }}
            >
              {t('aboutUs.introduction', { brand: 'LeaseSphere' })}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: '18px', lineHeight: 1.8, marginBottom: '40px' }}
            >
              {t('aboutUs.details', { brand: 'LeaseSphere' })}
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                color: '#333',
                fontWeight: 'bold',
                marginBottom: '20px',
              }}
            >
              {t('aboutUs.pricingTitle')}
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: '20px', fontSize: '16px', lineHeight: 1.7 }}
            >
              {t('aboutUs.pricingDescription')}
            </Typography>

            <Grid container spacing={4}>
              {subscription.map((plan, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box
                    sx={{
                      backgroundColor:
                        plan.styleClass === 'free'
                          ? '#FFA500' // Orange
                          : plan.styleClass === 'basic'
                            ? '#ff5f5f' // Red
                            : plan.styleClass === 'standard'
                              ? '#427700' // Dark Green
                              : plan.styleClass === 'premium'
                                ? '#4d5fff' // Blue
                                : '#2196F3',
                      color: '#fff',
                      padding: '20px',
                      borderRadius: '8px',
                      textAlign: 'center',
                      transition: 'transform 0.3s',
                      '&:hover': {
                        transform: 'scale(1.15)',
                      },
                    }}
                  >
                    <Typography variant="h6" component="h3">
                      {t(`subscriptions.${plan.name.toLowerCase()}.name`)}
                    </Typography>
                    <Typography variant="body2">
                      {plan.features
                        .map((feature) =>
                          t(
                            `subscriptions.features.${feature.toLowerCase().replace(/\s+/g, '')}`
                          )
                        )
                        .join(', ')}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h3"
                      sx={{ marginTop: '10px' }}
                    >
                      {plan.price} / {t('subscriptions.monthly')}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box
              sx={{
                marginTop: '60px',
                paddingTop: '30px',
                borderTop: '1px solid #ddd',
                textAlign: 'center',
                color: '#fff',
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: '10px', fontWeight: 'bold' }}
              >
                {t('contactUs.title')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '20px',
                  marginBottom: '20px',
                  flexWrap: 'wrap',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Email sx={{ marginRight: '8px' }} />
                  <Link
                    href="mailto:support@leasesphere.com"
                    underline="none"
                    sx={{ color: '#fff' }}
                  >
                    support@leasesphere.com
                  </Link>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Phone sx={{ marginRight: '8px' }} />
                  <Link
                    href="tel:+2348130715177"
                    underline="none"
                    sx={{ color: '#fff' }}
                  >
                    +234 813 071 5177
                  </Link>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocationOn sx={{ marginRight: '8px' }} />
                  <Typography variant="body2" sx={{ color: '#fff' }}>
                    {t('contactUs.location')}
                  </Typography>
                </Box>
              </Box>

              <Typography
                variant="body2"
                sx={{ color: '#fff', fontSize: '12px' }}
              >
                © {currentYear} | 🔋 {t('poweredBy', { brand: 'LeaseSphere' })}
              </Typography>
            </Box>
          </Container>
        </Box>
      </main>
    </div>
  );
};

export default AboutUs;

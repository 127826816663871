import React, { useState, useEffect, ChangeEvent } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { api } from '../utils/api';
import { User } from '../utils/interfaces';
import { toast } from 'react-toastify';
import Spinner from '../utils/Spinner';
import { countries, Country } from '../utils/countries';
import avatars from '../utils/avatars';
import AvatarModal from '../helper/AvatarModal';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Grid,
  Avatar,
  Modal,
  Backdrop,
  Typography,
  TextField,
  Fade,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { clearAllData } from '../utils/indexedDB';
import { useUserStore } from '../stores/UserStore';
import { deleteKeyStore } from '../utils/indexedDB';

// Define the CountryDropdownProps interface for the CountryDropdown component
interface CountryDropdownProps {
  countries: Country[];
  value: string;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

// Define the CountryDropdown component
const CountryDropdown: React.FC<CountryDropdownProps> = ({
  countries,
  value,
  onChange,
  disabled,
}) => (
  <FormControl fullWidth>
    <InputLabel>Country</InputLabel>
    <Select
      value={value}
      //@ts-ignore
      onChange={onChange}
      disabled={disabled}
      variant="outlined"
    >
      {countries.map((country) => (
        <MenuItem key={country.currency} value={country.name}>
          {country.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

function Profile() {
  const { t } = useTranslation();
  const { user, setUser, loadUserFromIndexedDB } = useUserStore();
  const [profile, setProfile] = useState<User | null>(null);
  const [showUserID, setShowUserID] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // Fetch user details from IndexedDB or API
  useEffect(() => {
    const fetchUser = async () => {
      try {
        await loadUserFromIndexedDB();
        if (useUserStore.getState().user) {
          setProfile(useUserStore.getState().user);
        }
      } catch (error) {
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchUser();
  }, [loadUserFromIndexedDB]);

  // Handle input change in profile form
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setProfile((prevProfile) => ({
      ...prevProfile!,
      [name]: value,
    }));
  };

  // Handle country change in the dropdown
  const handleCountryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedCountry = countries.find(
      (country) => country.name === event.target.value
    );
    if (selectedCountry && profile) {
      setProfile({
        ...profile,
        Country: selectedCountry.name,
        Currency: selectedCountry.currency,
      });
    }
  };

  // Handle avatar selection
  const handleSelectAvatar = (avatarUrl: string) => {
    if (!profile) {
      toast.error('Profile is not defined');
      return;
    }

    const updatedUser: Partial<User> = {
      ...profile,
      Avatar: avatarUrl,
    };

    api
      .uploadAvatar(updatedUser as User)
      .then(async (response) => {
        if (response.success) {
          setProfile(updatedUser as User);
          setUser(updatedUser as User); // Update Zustand store
          await deleteKeyStore('profileDetails');
          toast.success(t('avatar_upload_success'));
        } else {
          toast.error(t('avatar_upload_fail'));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  // Handle profile form submission
  const handleSubmit = () => {
    if (profile) {
      api
        .updateUserProfile(profile)
        .then(async () => {
          setUser(profile); // Update Zustand store
          await deleteKeyStore('profileDetails');
          toast.success(t('profile_update_success'));
          setIsEditable(false);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  // Toggle edit mode
  const toggleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  // Cancel edit and reset profile to initial state
  const cancelEdit = () => {
    setProfile(useUserStore.getState().user);
    setIsEditable(false);
  };

  // Handle account deletion
  const handleDeleteAccount = () => {
    if (profile && profile.Email) {
      api
        .deleteAccount(profile.Email)
        .then((response) => {
          toast.success(response);
          localStorage.clear();
          clearAllData()
            .then(() => {
              window.location.href = '/';
            })
            .catch((error) => {
              console.error('Error clearing IndexedDB:', error.message);
              window.location.href = '/';
            });
        })
        .catch((error) => {
          toast.warning(error.message);
        });
      setOpenDeleteModal(false);
    }
  };

  if (!profile) {
    return <Spinner />;
  }

  return (
    <div className="overview-container">
      <main
        className="profile-content"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: '20px',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item md={3}>
              <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                <Avatar
                  className="rounded-circle mt-5"
                  sx={{ width: 150, height: 150, cursor: 'pointer' }}
                  src={profile.Avatar || 'https://path/to/default/avatar.jpg'}
                  alt="Profile"
                  onClick={() => setShowModal(true)} // Open modal on click
                />
                <span className="font-weight-bold">{profile.Email}</span>
                {/* Display UserID */}
                <div className="d-flex align-items-center mt-3">
                  <InputLabel className="labels">{t('unique_id')}</InputLabel>
                  <div className="d-flex align-items-center">
                    <TextField
                      type={showUserID ? 'text' : 'password'}
                      className="form-control"
                      value={profile.UserID}
                      //@ts-ignore
                      readOnly
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowUserID((prev) => !prev)}
                              edge="end"
                            >
                              {showUserID ? (
                                <AiOutlineEyeInvisible />
                              ) : (
                                <AiOutlineEye />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ width: '100%' }} // Increase width for Unique ID
                    />
                  </div>
                </div>
                {/* Delete Account*/}
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setOpenDeleteModal(true)}
                  sx={{ mt: 2 }}
                >
                  {t('delete_account')}
                </Button>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center mb3">
                  <h4 className="text-right">{t('profile_settings')}</h4>
                  {isEditable ? (
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={cancelEdit}
                        sx={{ mr: 2 }} // Add margin-right
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{ ml: 2 }} // Add margin-left
                      >
                        {t('save')}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toggleEdit}
                    >
                      {t('edit')}
                    </Button>
                  )}
                </div>
                <TextField
                  label={t('fullname')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_full_name')}
                  name="Fullname"
                  value={profile.Fullname}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3, mt: 3 }}
                />
                <TextField
                  label={t('phone_number')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_phone_number')}
                  name="Phone"
                  value={profile.Phone}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t('address')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_address')}
                  name="Address"
                  value={profile.Address}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t('city')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_city')}
                  name="City"
                  value={profile.City}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t('postal_code')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_postal_code')}
                  name="PostalCode"
                  value={profile.PostalCode}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3 }}
                />
                <CountryDropdown
                  countries={countries}
                  value={profile.Country || ''}
                  onChange={handleCountryChange}
                  disabled={!isEditable}
                />
              </div>
            </Grid>
          </Grid>
          {showModal && (
            <AvatarModal
              avatars={avatars}
              onSelect={handleSelectAvatar}
              onClose={() => setShowModal(false)}
            />
          )}
        </Container>
      </main>
      {/* Modal for delete confirmation */}
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDeleteModal}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh', // Center vertically
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                maxWidth: '400px',
                width: '100%',
                textAlign: 'center',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '5px',
              }}
            >
              <Typography variant="h6" gutterBottom>
                {t('delete_account_confirmation')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteAccount}
                style={{ marginRight: '10px' }}
              >
                {t('yes')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t('no')}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Profile;

import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import { Tenant, RentalIncomeResponse } from '../utils/interfaces';
import { useTranslation } from 'react-i18next';

function ProfitAndLoss() {
  // State variables for managing data fetching, loading state, and error handling
  const { t } = useTranslation();
  const [totalRooms, setTotalRooms] = useState<number>(0);
  const [avgRoomPrice, setAvgRoomPrice] = useState<number>(0);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: RentalIncomeResponse = await api.getRentalIncome();
        setTotalRooms(response.TotalRooms);
        setAvgRoomPrice(parseInt(response.AvgRoomPrice));
        setTenants(response.Tenants);
        setLoading(false);
      } catch (error) {
        //@ts-ignore
        toast.warning(error.message);
        setError(t('noData'));
        setLoading(false);
      }
    };

    fetchData();
  }, [t]);

  // Function to calculate financial data (revenue, expenses, profit, and loss) based on tenant contracts and room prices
  const calculateFinancials = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: 5 },
      (_, index) => currentYear - 4 + index
    ); // Creating an array of five years starting from four years ago to the current year

    // Calculating revenue data for each year
    const revenueData = years.map((year) => {
      let totalRevenue = 0;
      tenants.forEach((tenant) => {
        const contractStart = new Date(tenant.StartDate);
        const contractEnd = new Date(tenant.EndDate);

        // Check if the tenant was active during the year
        if (
          contractStart.getFullYear() <= year &&
          contractEnd.getFullYear() >= year
        ) {
          const startMonth =
            contractStart.getFullYear() === year
              ? contractStart.getMonth() + 1
              : 1;
          const endMonth =
            contractEnd.getFullYear() === year
              ? contractEnd.getMonth() + 1
              : 12;

          const monthsActive = endMonth - startMonth + 1;
          //@ts-ignore

          const monthlyRevenue = tenant.RoomPrice / 12;
          totalRevenue += monthlyRevenue * monthsActive;
        }
      });
      return totalRevenue;
    });

    // Calculating expenses data for each year
    const expensesData = years.map((year) => {
      // Expenses should only be calculated if there are active tenants
      const activeTenants = tenants.filter((tenant) => {
        const contractStart = new Date(tenant.StartDate);
        const contractEnd = new Date(tenant.EndDate);
        return (
          contractStart.getFullYear() <= year &&
          contractEnd.getFullYear() >= year
        );
      });

      return activeTenants.length > 0 ? avgRoomPrice * totalRooms : 0;
    });

    // Calculating profit data for each year
    const profitData = years.map((year, index) => {
      const revenue = revenueData[index];
      const expenses = expensesData[index];
      return Math.max(revenue - expenses, 0);
    });

    const lossData = years.map((year, index) => {
      const revenue = revenueData[index];
      const expenses = expensesData[index];
      return Math.max(expenses - revenue, 0);
    });

    return { years, revenueData, profitData, lossData };
  };

  // Destructuring calculated financials data
  const { years, revenueData, profitData, lossData } = calculateFinancials();

  // Retrieving currency symbol from local storage
  const currencySymbol = localStorage.getItem('Currency');

  // Chart data configuration
  const data: ChartData<'line'> = {
    labels: years.map((year) => `${year - 1} - ${year}`),
    datasets: [
      {
        label: t('profit'),
        data: profitData,
        borderColor: 'green',
        backgroundColor: 'rgba(0, 128, 0, 0.1)',
        fill: false,
      },
      {
        label: t('revenue'),
        data: revenueData,
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.1)',
        fill: false,
      },
      {
        label: t('loss'),
        data: lossData,
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        fill: false,
      },
    ],
  };

  // Chart options configuration
  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataset, parsed } = context;
            let label = dataset?.label || '';
            if (label) {
              label += ': ';
            }
            if (parsed.y !== null) {
              // If parsed y-value exists
              const roundedValue = Math.round(parsed.y); // Rounding parsed y-value
              label +=
                currencySymbol +
                new Intl.NumberFormat('en-US').format(roundedValue);
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t('inMillions'),
        },
      },
    },
  };

  // Conditional rendering based on loading state
  if (loading) {
    return (
      <div className="overview-container">
        <main className="profit-and-loss-content">
          <div className="container">
            <div className="profit-container p-4">
              <h2>{t('profitAndLossYear')}</h2>
              <p>{t('loading')}</p>
            </div>
          </div>
        </main>
      </div>
    );
  }

  // Conditional rendering based on error state
  if (error) {
    return (
      <div className="overview-container">
        <main className="profit-and-loss-content">
          <div className="container">
            <div className="profit-container p-4">
              <h2>{t('profitAndLossYear')}</h2>
              <p>{error}</p>
            </div>
          </div>
        </main>
      </div>
    );
  }

  // Rendering chart and data when neither loading nor error
  return (
    <div className="overview-container">
      <main className="profit-and-loss-content">
        <div className="container">
          <div className="profit-container p-4">
            <h2>{t('profitAndLossYear')}</h2>
            <div className="chart-container">
              <Line data={data} options={options} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ProfitAndLoss;

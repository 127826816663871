import React, { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { User, ApiResponse } from '../utils/interfaces';
import { validateEmail, validatePassword } from '../helper/validation';
import { useNavigate } from 'react-router-dom';
import { api } from '../utils/api';
import { Authenticator } from '../helper/Authenticator';
import { toast } from 'react-toastify';
import { setEncryptedAccessToken } from '../utils/TokenStorage';
import ProppLogo from '../IOT/Logo';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function Login() {
  // Define state variables for form visibility, input fields, error messages, etc.
  const { t } = useTranslation();
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showPasswordTip, setShowPasswordTip] = useState(false);
  const [errorMsgLogin, setErrorMsgLogin] = useState('');
  const [errorMsgRegister, setErrorMsgRegister] = useState('');
  const [errorMsgReset, setErrorMsgReset] = useState(''); // New state for reset password error
  const [rememberMe, setRememberMe] = useState(false);
  const [userRoleType, setUserRoleType] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // New state for confirm password visibility
  const navigate = useNavigate();
  const [honeypot, setHoneypot] = useState('');

  // Toggle between login and registration forms
  const toggleForm = () => {
    setShowLoginForm(!showLoginForm);
    setShowForgotPassword(false);
  };

  // Toggle to forgot password form
  const toggleForgetForm = () => {
    setShowLoginForm(false);
    setShowForgotPassword(true);
  };

  // Handlers for input changes
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(e.target.value);
  };

  const handleUserRoleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUserRoleType(e.target.value);
  };

  // Handle user registration
  const handleRegister = () => {
    if (honeypot) {
      console.log('Bot detected - honeypot filled');
      return;
    }

    if (
      !validateEmail(email) ||
      !validatePassword(password) ||
      password !== passwordConfirm
    ) {
      console.log('Validation failed');
      setShowPasswordTip(true);
      setErrorMsgRegister(t('validation_error_fields'));
      return;
    }

    if (!userRoleType) {
      setErrorMsgRegister(t('validation_error_role'));
      toast.error(t('please_select_role'));
      return;
    }

    const userName = email.split('@')[0];
    const registerData: User = {
      Username: userName,
      Password: password,
      Email: email,
      UserRoleType: userRoleType,
    };

    api
      .register(registerData)
      .then((response) => {
        const accessToken = response.headers['authorization']?.split(' ')[1];
        if (accessToken) {
          setEncryptedAccessToken(accessToken);
          // @ts-ignore
          Authenticator.setAuth(true);
          navigate('/overview', { replace: true });
        }
      })
      .catch((error: ApiResponse) => {
        toast.error(error.message);
        console.log(error.message);
      });
  };

  // Handle user login
  const handleLogin = () => {
    if (honeypot) {
      console.log('Bot detected - honeypot filled');
      return;
    }

    if (!validateEmail(email) || !validatePassword(password)) {
      console.log('Validation failed');
      setErrorMsgLogin(t('invalid_email_password'));
      return;
    }

    const loginData: User = {
      Email: email,
      Password: password,
      RememberMe: rememberMe,
    };

    api
      .login(loginData)
      .then((response) => {
        const accessToken = response.headers['authorization']?.split(' ')[1];
        if (accessToken) {
          setEncryptedAccessToken(accessToken);
          // @ts-ignore
          Authenticator.setAuth(true);
          navigate('/overview', { replace: true });
        }
      })
      .catch((error: ApiResponse) => {
        toast.error(error.message);
        console.log(error.message);
      });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  // Handle password reset request
  const handleResetPassword = () => {
    if (honeypot) {
      console.log('Bot detected - honeypot filled');
      return;
    }

    if (!validateEmail(email)) {
      console.log('Validation failed');
      setErrorMsgReset(t('invalid_email'));
      return;
    }

    api
      .resetPasswordEmail(email)
      .then((response) => {
        toast.success(t(response.message));
        setShowLoginForm(true); // Go back to login form
        setShowForgotPassword(false);
        setErrorMsgReset(''); // Clear any previous error messages
      })
      .catch((error: ApiResponse) => {
        setErrorMsgReset(error.message); // Update the error message for reset password
        toast.error(error.message);
        console.log(error.message);
      });
  };

  // Handle navigation to About Us page
  const goToAboutUs = () => {
    navigate('/about-us');
  };

  return (
    <div className="login-container" onKeyDown={handleKeyPress}>
      <div className="login-form">
        {/* Honeypot field */}
        <input
          type="text"
          name="honeypot"
          style={{ display: 'none' }}
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
        />
        {showLoginForm ? (
          <>
            {/* // Show Login Form */}
            <div className="logo">
              {/* @ts-ignore */}
              <ProppLogo id="propLogo" />
            </div>
            <TextField
              label={t('email')}
              type="email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
            />
            <TextField
              label={t('password')}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
              }
              label={t('remember_me')}
            />
            <Typography color="error">{errorMsgLogin}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              fullWidth
            >
              {t('login')}
            </Button>
            <p className="message">
              {t('forgot_password')}{' '}
              <a onClick={toggleForgetForm}>{t('forgot_password')}</a>
            </p>
            <p className="message">
              {t('new_customer')} <a onClick={toggleForm}>{t('sign_up')}</a>
            </p>
            <p className="message">
              <a onClick={goToAboutUs}>{t('about_us')}</a>
            </p>
          </>
        ) : showForgotPassword ? (
          // Show Forgot Password Form
          <>
            <div className="logo">
              {/* @ts-ignore */}
              <ProppLogo id="propLogo" />
            </div>
            <TextField
              label={t('email')}
              type="email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
            />
            <Typography color="error">{errorMsgReset}</Typography>{' '}
            {/* Display error message here */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetPassword}
              fullWidth
            >
              {t('reset_password')}
            </Button>
            <p className="message">
              {t('already_registered')}{' '}
              <a onClick={toggleForm}>{t('sign_in')}</a>
            </p>
          </>
        ) : (
          <>
            {/* // Show Register Form */}
            <div className="logo">
              {/* @ts-ignore */}
              <ProppLogo id="propLogo" />
            </div>
            <TextField
              label={t('email')}
              type="email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
            />
            <TextField
              label={t('password')}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              onFocus={() => setShowPasswordTip(true)}
              onBlur={() => setShowPasswordTip(false)}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={t('confirm_password')}
              type={showConfirmPassword ? 'text' : 'password'}
              value={passwordConfirm}
              onChange={handlePasswordConfirmChange}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {showPasswordTip && (
              <div
                className="password-tip"
                style={{ display: showPasswordTip ? 'block' : 'none' }}
              >
                <ul>
                  <li>{t('password_tip_length')}</li>
                  <li>{t('password_tip_case')}</li>
                  <li>{t('password_tip_number_symbol')}</li>
                </ul>
              </div>
            )}
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>{t('select_user')}</InputLabel>
              <Select
                value={userRoleType}
                //@ts-ignore
                onChange={handleUserRoleTypeChange}
                label={t('select_user')}
              >
                <MenuItem value="" disabled>
                  {t('select_user')}
                </MenuItem>
                <MenuItem value="Landlord">{t('landlord')}</MenuItem>
                <MenuItem value="Caretaker">{t('caretaker')}</MenuItem>
              </Select>
            </FormControl>
            <Typography color="error">{errorMsgRegister}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRegister}
              fullWidth
            >
              {t('register')}
            </Button>
            <p className="message">
              {t('already_registered')}{' '}
              <a onClick={toggleForm}>{t('sign_in')}</a>
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default Login;

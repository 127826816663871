import { create } from 'zustand';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import { getCurrencyCodeByCountry } from '../utils/countries';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { useUserStore } from './UserStore';

interface ConversionRateStore {
  conversionRate: string;
  setConversionRate: (rate: string) => void;
  fetchConversionRate: () => Promise<void>;
  loadConversionRateFromIndexedDB: () => Promise<void>;
}

export const useConversionRateStore = create<ConversionRateStore>((set) => ({
  conversionRate: '',

  setConversionRate: (rate: string) => set({ conversionRate: rate }),

  loadConversionRateFromIndexedDB: async () => {
    try {
      const savedRate = await getData('conversionRate');
      if (savedRate) {
        const decryptedData = decryptData(savedRate.data);
        set({ conversionRate: decryptedData });
      } else {
        await useConversionRateStore.getState().fetchConversionRate();
      }
    } catch (error) {
      toast.error('Failed to load conversion rate from IndexedDB');
    }
  },

  fetchConversionRate: async () => {
    try {
      const { user } = useUserStore.getState();
      const country = user?.Country; // Get the CountryCode from the user store

      if (country) {
        const currencyCode = getCurrencyCodeByCountry(country);

        if (currencyCode) {
          const rate = await api.getCurrencyConversionRate(currencyCode);
          const encryptedData = encryptData(rate.toString());
          await saveData('conversionRate', encryptedData);
          set({ conversionRate: rate.toString() });
        } else {
          toast.error('Currency code not found for the selected country');
        }
      } else {
        toast.error('Country code not found for the user');
      }
    } catch (error) {
      toast.error('Failed to fetch conversion rate');
      set({
        conversionRate:
          process.env.REACT_APP_DOLLAR_TO_NAIRA_CONVERSION || '1000',
      });
    }
  },
}));

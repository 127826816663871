import { create } from 'zustand';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';

interface RoomStore {
  rooms: any[] | null;
  setRooms: (rooms: any[]) => void;
  fetchRoomsFromApi: (propertyId: string) => Promise<void>;
  loadRoomsFromIndexedDB: (propertyId: string) => Promise<void>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const useRoomStore = create<RoomStore>((set) => ({
  rooms: null,
  loading: false,

  // Function to set rooms in the store
  setRooms: (rooms: any[]) => set({ rooms }),

  // Function to set loading state
  setLoading: (loading: boolean) => set({ loading }),

  // Function to load rooms data from IndexedDB
  loadRoomsFromIndexedDB: async (propertyId: string) => {
    try {
      set({ loading: true });
      const savedRooms = await getData(`rooms_${propertyId}`);

      if (savedRooms && savedRooms.data.length > 0) {
        const decryptedData = decryptData(savedRooms.data);
        const rooms: any[] = JSON.parse(decryptedData);
        set({ rooms }); // Set the rooms in the Zustand store
      } else {
        // If no data in IndexedDB, fetch from API
        await useRoomStore.getState().fetchRoomsFromApi(propertyId);
      }

      set({ loading: false });
    } catch (error) {
      toast.error('Failed to load rooms from IndexedDB');
      set({ loading: false });
    }
  },

  // Function to fetch rooms from the API and save to IndexedDB
  fetchRoomsFromApi: async (propertyId: string) => {
    try {
      set({ loading: true });
      const response = await api.getRoomsByID(propertyId);

      // Save the rooms to Zustand state
      set({ rooms: response });

      // Encrypt and save the rooms data to IndexedDB
      const encryptedData = encryptData(JSON.stringify(response));
      await saveData(`rooms_${propertyId}`, encryptedData);

      set({ loading: false });
    } catch (error) {
      // @ts-ignore
      toast.error(error.message);
      set({ loading: false });
      // @ts-ignore
      console.error('Error fetching rooms:', error.message);
    }
  },
}));

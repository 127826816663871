import { useState } from 'react';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import ErrorMessages from '../helper/ErrorMessages';
import { useTranslation } from 'react-i18next';
import Spinner from '../utils/Spinner';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Modal,
} from '@mui/material';
import TermsConditionsModal from '../helper/TermsConditionsModal';
import PrivacyPolicyModal from '../helper/PrivacyPolicyModal';

const Information = () => {
  const { t } = useTranslation();
  const errorMessages = new ErrorMessages();
  const currentYear = new Date().getFullYear();
  const [showModal, setShowModal] = useState(false);
  const [messageSubject, setMessageSubject] = useState('');
  const [messageDescription, setMessageDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setLoading(false);
  };

  const handleSendMessage = () => {
    setLoading(true);

    if (!messageSubject || !messageDescription) {
      setLoading(false);
      toast.error(t('fieldsEmpty'));
      return;
    }

    const messageData = {
      Subject: messageSubject,
      Description: messageDescription,
    };

    api
      .contactUsEmail(messageData)
      .then((response) => {
        setLoading(false);
        setShowModal(false);
        toast.success(t('message_sent'));
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = errorMessages.getErrorMessage(
          error.statusCode,
          error.context
        );
        toast.error(errorMessage);
      });
  };

  return (
    <div className="overview-container">
      <main className="information-content">
        <Box sx={{ padding: '20px' }}>
          <Container maxWidth="lg">
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ color: '#000' }}
            >
              {t('categories')}
            </Typography>
            <Grid container spacing={3}>
              {['partners', 'updates', 'affiliate'].map((category, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      backgroundColor: '#4D9AD3',
                      color: '#fff',
                      padding: '20px',
                      borderRadius: '8px',
                      textAlign: 'center',
                      transition: 'transform 0.3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    <Typography variant="h6" component="h2">
                      {t(category)}
                    </Typography>
                    <Button variant="contained" sx={{ marginTop: '10px' }}>
                      {t('read')}
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
              <Button
                variant="outlined"
                sx={{ color: '#000', borderColor: '#000' }}
                onClick={handleOpenModal}
              >
                {t('contact_us')}
              </Button>
            </Box>

            {/* Modal for Contact Us */}
            <Modal
              open={showModal}
              onClose={handleCloseModal}
              aria-labelledby="contact-us-modal"
              aria-describedby="contact-us-modal-description"
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '90%',
                  maxWidth: '400px',
                  bgcolor: '#fff',
                  boxShadow: 24,
                  p: 4,
                  overflowY: 'auto',
                }}
              >
                <Typography variant="h6" component="h2">
                  {t('contact_us')}
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  id="message-subject"
                  label={t('subject')}
                  value={messageSubject}
                  onChange={(e) => setMessageSubject(e.target.value)}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  id="message-description"
                  label={t('description')}
                  multiline
                  rows={3}
                  value={messageDescription}
                  onChange={(e) => setMessageDescription(e.target.value)}
                />
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCloseModal}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendMessage}
                  >
                    {t('send')}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {/* Spinner component */}
            {loading && (
              <Box
                sx={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 9999,
                }}
              >
                <Spinner />
              </Box>
            )}

            {/* Footer section */}
            <Box
              sx={{
                marginTop: '40px',
                textAlign: 'center',
                fontSize: '12px',
                color: '#000',
              }}
            >
              <Box sx={{ marginTop: '20px' }}>
                {/* Links to Terms and Conditions and Privacy Policy */}
                <TermsConditionsModal />
                <PrivacyPolicyModal />
              </Box>
              <Typography
                variant="body2"
                sx={{ color: '#000', marginTop: '20px', fontSize: '12px' }}
              >
                © {currentYear} | {t('powered_by')}
              </Typography>
            </Box>
          </Container>
        </Box>
      </main>
    </div>
  );
};

export default Information;

import { create } from 'zustand';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Message } from '../utils/interfaces';

interface MessageStore {
  messages: Message[]; // Use Message[] instead of Message[] | null
  setMessages: (messages: Message[]) => void;
  fetchMessagesFromApi: () => Promise<void>;
  loadMessagesFromIndexedDB: () => Promise<void>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const useMessageStore = create<MessageStore>((set) => ({
  messages: [], // Initialize with an empty array
  loading: false,

  // Function to set messages in the store
  setMessages: (messages: Message[]) => set({ messages }),

  // Function to set loading state
  setLoading: (loading: boolean) => set({ loading }),

  // Function to load messages data from IndexedDB
  loadMessagesFromIndexedDB: async () => {
    try {
      set({ loading: true });
      const savedMessages = await getData('messages');

      if (savedMessages && savedMessages.data.length > 0) {
        const decryptedData = decryptData(savedMessages.data);
        const messages: Message[] = JSON.parse(decryptedData);
        set({ messages }); // Set the messages in the Zustand store
      } else {
        // If no data in IndexedDB, fetch from API
        await useMessageStore.getState().fetchMessagesFromApi();
      }

      set({ loading: false });
    } catch (error) {
      toast.error('Failed to load messages from IndexedDB');
      set({ loading: false });
    }
  },

  // Function to fetch messages from the API and save to IndexedDB
  fetchMessagesFromApi: async () => {
    try {
      set({ loading: true });
      const response = await api.getMessages();

      if (response && Array.isArray(response)) {
        set({ messages: response as Message[] }); // Ensure correct typing

        // Encrypt and save the messages data to IndexedDB
        const encryptedData = encryptData(JSON.stringify(response));
        await saveData('messages', encryptedData);
      } else {
        toast.error('Invalid response format.');
      }

      set({ loading: false });
    } catch (error) {
      // @ts-ignore
      toast.error(error.message);
      set({ loading: false });
    }
  },
}));

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../utils/api';
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Modal,
  Typography,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useUserStore } from '../stores/UserStore';
import { clearAllData } from '../utils/indexedDB';

//@ts-ignore
import MonthlyImage from '../assets/img/monthly.jpg';
//@ts-ignore
import YearlyImage from '../assets/img/yearly.jpg';
//@ts-ignore
import ProfitLossImage from '../assets/img/profit_loss.jpg';
//@ts-ignore
import TenantStatusImage from '../assets/img/tenant_status.jpg';

const Overview = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user, loadUserFromIndexedDB } = useUserStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentDate, setCurrentDate] = useState<string>('');
  const [hasNotifications, setHasNotifications] = useState<boolean>(false);
  const [greeting, setGreeting] = useState<string>('');
  const [isSubscriptionActive, setIsSubscriptionActive] =
    useState<boolean>(false);
  const [modalMessages, setModalMessages] = useState<string[]>([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await loadUserFromIndexedDB();

        if (useUserStore.getState().user) {
          const userState = useUserStore.getState().user;
          const state = userState?.IsActive;
          setIsSubscriptionActive(!!state);

          // Format current date and set greeting message
          const now = new Date();
          const formattedDate = format(now, 'dd MMM yyyy');
          setCurrentDate(formattedDate);

          setGreeting(
            getGreeting(userState?.Fullname || 'Guest', now.getHours())
          );

          // Check user information for modal messages
          checkUserInformation(userState);

          fetchNotifications();
        }
      } catch (error) {
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchUser();
  }, [loadUserFromIndexedDB, i18n.language]);

  const checkUserInformation = (user: any) => {
    const { isMessageDismissed } = useUserStore.getState();

    // If the message has already been dismissed, do not show again
    if (isMessageDismissed) {
      return;
    }

    const messages = [];

    if (!user?.Fullname || !user?.Country) {
      messages.push(t('profile_update_message'));
    }

    if (user?.VerificationExpiryDate) {
      const expiryDate = format(
        new Date(user.VerificationExpiryDate),
        'dd MMM yyyy'
      );
      messages.push(t('email_verification_message', { expiryDate }));
    }

    if (messages.length > 0) {
      setModalMessages(messages);
    }
  };

  const handleCloseModal = () => {
    useUserStore.getState().setMessageDismissed(true);
    setModalMessages([]);
  };

  // Function to get greeting message based on user's name and hour of the day
  const getGreeting = (fullname: string, hour: number): string => {
    let greetingMsg = '';
    if (hour >= 5 && hour < 12) {
      greetingMsg = t('good_morning');
    } else if (hour >= 12 && hour < 18) {
      greetingMsg = t('good_afternoon');
    } else {
      greetingMsg = t('good_evening');
    }
    return `${greetingMsg}, ${fullname}`;
  };

  // Function to fetch notifications for the user
  const fetchNotifications = () => {
    api
      .getAccessRequests()
      .then((response) => {
        const notifications = response;
        const hasPendingNotifications = notifications.some(
          (notification: any) =>
            notification.Status === 'Pending' ||
            notification.Status === 'Revoked'
        );
        setHasNotifications(hasPendingNotifications);
      })
      .catch((error) => {
        console.error('Error fetching notifications:', error.message);
      });
  };

  // Function to handle click on language menu icon
  const handleLanguageMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing of language menu
  const handleLanguageMenuClose = () => {
    setAnchorEl(null);
  };

  // Function to change the language and close the language menu
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    handleLanguageMenuClose();
    clearAllData();
    api
      .updateUserLanguage(lng)
      .then(async () => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };

  // Function to handle click on analysis buttons
  const handleAnalysisClick = (route: string) => {
    // Allow navigation if the route is '/information'
    if (route === '/information') {
      navigate(route);
      return;
    }
    // Otherwise, check if grid is enabled
    if (!canViewAnalysis) {
      toast.error(t('restrictionCaretaker'));
      return;
    }
    navigate(route);
  };

  // Function to handle click on notifications icon
  const handleNotificationsClick = () => {
    navigate('/notifications');
  };

  // Determine if grid options are enabled based on user's role and subscription
  const canViewAnalysis =
    user?.UserRoleType === 'Landlord' &&
    user?.SubscriptionType === 'Premium' &&
    isSubscriptionActive;

  // JSX structure for rendering the Overview component
  return (
    <div className="overview-container">
      {/* Modal for displaying messages */}
      <Modal
        open={modalMessages.length > 0}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {t('updateProfile')}
          </Typography>
          {modalMessages.map((message, index) => (
            <Typography key={index} sx={{ mt: 2 }}>
              {index + 1}: {message}
            </Typography>
          ))}
          <Button onClick={handleCloseModal} variant="contained" sx={{ mt: 2 }}>
            Do not show again
          </Button>
        </Box>
      </Modal>

      {/* Rest of your component */}
      <nav className="navbar">
        <div className="search-bar">{greeting}</div>
        <div className="navbar-actions">
          {/* Language selection button */}
          <Tooltip title={t('select_language')} placement="bottom">
            <IconButton
              className="language-button"
              onClick={handleLanguageMenuClick}
              style={{ color: 'black' }}
            >
              <LanguageIcon />
            </IconButton>
          </Tooltip>
          {/* Language menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLanguageMenuClose}
          >
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            <MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>
          </Menu>
          {/* Notifications button */}
          <Tooltip title={t('view_notifications')} placement="bottom">
            <IconButton
              className="notification-button"
              style={{ color: 'black' }}
              onClick={handleNotificationsClick}
            >
              {/* Notifications icon with optional red dot for new notifications */}
              <NotificationsIcon />
              {hasNotifications && (
                <InfoIcon
                  className="info-icon"
                  style={{ color: 'red', marginLeft: '5px' }}
                />
              )}
            </IconButton>
          </Tooltip>
          {/* Button displaying current date */}
          <Button
            className="date-button"
            variant="text"
            style={{ color: 'black', pointerEvents: 'none' }}
            disabled
          >
            {currentDate}
          </Button>
        </div>
      </nav>
      <main className="overview-content">
        {/* Grid container for displaying analysis options */}
        <div
          className={`grid-container ${canViewAnalysis ? 'grid-enabled' : ''}`}
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${canViewAnalysis ? 2 : 1}, 1fr)`,
          }}
        >
          {canViewAnalysis && (
            <>
              {/* Monthly analysis button */}
              <Grid item>
                <Tooltip
                  title="View Monthly Analysis"
                  placement="top" // Tooltip appears above
                  sx={{ fontSize: '1.5rem' }} // Increase font size
                >
                  <Button
                    className="square-option"
                    onClick={() => handleAnalysisClick('/monthly-analysis')}
                    style={{
                      height: '250px',
                      borderRadius: '35px',
                      pointerEvents: 'auto',
                      backgroundImage: `url(${MonthlyImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
                      transition:
                        'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    }}
                  ></Button>
                </Tooltip>
              </Grid>
              {/* Yearly analysis button */}
              <Grid item>
                <Tooltip
                  title="View Yearly Analysis"
                  placement="top" // Tooltip appears above
                  sx={{ fontSize: '1.5rem' }} // Increase font size
                >
                  <Button
                    className="square-option"
                    onClick={() => handleAnalysisClick('/yearly-analysis')}
                    style={{
                      height: '250px',
                      borderRadius: '35px',
                      pointerEvents: 'auto',
                      backgroundImage: `url(${YearlyImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
                      transition:
                        'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    }}
                  ></Button>
                </Tooltip>
              </Grid>
              {/* Profit and loss analysis button */}
              <Grid item>
                <Tooltip
                  title="View Profit and Loss Analysis"
                  placement="top" // Tooltip appears above
                  sx={{ fontSize: '1.5rem' }} // Increase font size
                >
                  <Button
                    className="square-option"
                    onClick={() => handleAnalysisClick('/profit-and-loss')}
                    style={{
                      height: '250px',
                      borderRadius: '35px',
                      pointerEvents: 'auto',
                      backgroundImage: `url(${ProfitLossImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
                      transition:
                        'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    }}
                  ></Button>
                </Tooltip>
              </Grid>
            </>
          )}
          {/* Information/Updates */}
          <Grid item>
            <Tooltip
              title="View Information and App Updates"
              placement="top" // Tooltip appears above
              sx={{ fontSize: '1.5rem' }} // Increase font size
            >
              <Button
                className="square-option"
                onClick={() => handleAnalysisClick('/information')}
                style={{
                  height: canViewAnalysis ? '250px' : '300px',
                  borderRadius: '35px',
                  pointerEvents: 'auto',
                  backgroundImage: `url(${TenantStatusImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
                  transition:
                    'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                }}
              ></Button>
            </Tooltip>
          </Grid>
        </div>
      </main>
    </div>
  );
};

export default Overview;

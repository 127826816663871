import { create } from 'zustand';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import { User } from '../utils/interfaces';
import avatars from '../utils/avatars';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';

interface UserStore {
  user: User | null;
  isMessageDismissed: boolean;
  setUser: (user: User) => void;
  setMessageDismissed: (dismissed: boolean) => void;
  fetchUserFromApi: () => Promise<void>;
  loadUserFromIndexedDB: () => Promise<void>;
}

export const useUserStore = create<UserStore>((set) => ({
  user: null,
  isMessageDismissed: false,

  // Function to set the user in the store
  setUser: (user: User) => set({ user }),

  setMessageDismissed: (dismissed: boolean) => {
    set({ isMessageDismissed: dismissed });
    saveData('isMessageDismissed', JSON.stringify(dismissed));
  },

  // Function to load user data from IndexedDB
  loadUserFromIndexedDB: async () => {
    try {
      const savedProfile = await getData('profileDetails');
      if (savedProfile) {
        const decryptedData = decryptData(savedProfile.data);
        const user: User = JSON.parse(decryptedData);
        set({ user }); // Set the user in the Zustand store

        const savedDismissed = await getData('isMessageDismissed');
        if (savedDismissed) {
          const decryptedDismissed = savedDismissed.data;
          const isDismissed = JSON.parse(decryptedDismissed);
          set({ isMessageDismissed: isDismissed });
        }
      } else {
        // If no data in IndexedDB, fetch from API
        await useUserStore.getState().fetchUserFromApi();
      }
    } catch (error) {
      toast.error('Failed to load user from IndexedDB');
      // @ts-ignore
      console.error('Error loading user from IndexedDB:', error.message);
    }
  },

  // Function to fetch user details from the API and save to IndexedDB
  fetchUserFromApi: async () => {
    try {
      const userDetails = await api.getUserDetails();
      const user: User = {
        UserID: userDetails.UserID,
        Username: userDetails.Username,
        Email: userDetails.Email,
        Phone: userDetails.Phone,
        Fullname: userDetails.Fullname
          ? userDetails.Fullname
          : userDetails.Username,
        Address: userDetails.Address,
        City: userDetails.City,
        PostalCode: userDetails.PostalCode,
        Country: userDetails.Country,
        Currency: userDetails.Currency,
        UserRoleType: userDetails.UserRoleType,
        Password: '',
        Avatar: userDetails.Avatar || avatars[14], // Set default avatar if not provided
        SubscriptionType: userDetails.SubscriptionType,
        IsActive: userDetails.IsActive,
        VerificationExpiryDate: userDetails.VerificationExpiryDate,
      };

      // Save the user data to Zustand state
      set({ user });

      // Encrypt and save the user data to IndexedDB
      const encryptedData = encryptData(JSON.stringify(user));
      await saveData('profileDetails', encryptedData);
    } catch (error) {
      // @ts-ignore
      toast.error(error.message);
      // @ts-ignore
      console.error('Error fetching user details:', error.message);
    }
  },
}));

import React from 'react';
import '../assets/proppie-logo.svg';

const ProppLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="235"
      height="51"
      viewBox="0 0 235 51"
    >
      <g transform="translate(-4414 934)">
        <g transform="translate(4414 -934)">
          <g transform="translate(0 0)">
            <text
              transform="translate(0 38)"
              fill="#4575B8"
              font-size="40"
              font-family="moonhouse"
            >
              <tspan x="0" y="0">
                LeaseSphere
              </tspan>
            </text>
          </g>
        </g>
        <g transform="translate(4624.273 -924.847)">
          <g transform="translate(0 0)">
            <text
              transform="translate(-0.273 5.847)"
              fill="#4575B8"
              font-size="8"
              font-family="moonhouse"
            >
              <tspan x="0" y="0">
                by GT
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ProppLogo;

import React from 'react';
import '../css/Spinner.css'; // Make sure to create the corresponding CSS file

const Spinner = () => (
  <div className="spinner-container">
    <div className="spinner"></div>
  </div>
);

export default Spinner;

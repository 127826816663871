import { useEffect, useRef } from 'react';
import { useConversionRateStore } from '../stores/ConversionRateStore';

const useScheduledConversionRateUpdate = () => {
  const { fetchConversionRate, loadConversionRateFromIndexedDB } =
    useConversionRateStore();
  const lastFetchTime = useRef<number | null>(null);

  useEffect(() => {
    const updateConversionRate = async () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Only fetch conversion rate if it's 8:00 AM or 3:00 PM and not called in the last hour
      if ((hours === 8 || hours === 15) && minutes === 0) {
        const currentTime = now.getTime();
        if (
          !lastFetchTime.current ||
          currentTime - lastFetchTime.current >= 3600000
        ) {
          // 1 hour
          lastFetchTime.current = currentTime;
          await fetchConversionRate();
        }
      }
    };

    // Initial load
    loadConversionRateFromIndexedDB();

    // Check every minute
    const intervalId = setInterval(updateConversionRate, 60000);

    return () => clearInterval(intervalId);
  }, [fetchConversionRate, loadConversionRateFromIndexedDB]);
};

export default useScheduledConversionRateUpdate;
